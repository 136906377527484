import React, { useEffect, useState, ComponentProps } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Stack } from 'components/Stack'
import { Text } from 'components/Text'
import { OnboardingSliderRoot, ImageWrapper, SliderButton } from './OnboardingSlider.styles'

const BENEFITS = [
  {
    id: '1',
    image: '/assets/animation-energy.svg',
    text: 'login:onboarding.amount',
  },
  {
    id: '2',
    image: '/assets/animation-hand.svg',
    text: 'login:onboarding.preservatives',
  },
  {
    id: '3',
    image: '/assets/animation-chicken.svg',
    text: 'login:onboarding.meat',
  },
]

type OnboardingSliderOwnProps = ComponentProps<typeof OnboardingSliderRoot>
export type OnboardingSliderProps = OnboardingSliderOwnProps & {}

export const OnboardingSlider = (_props: OnboardingSliderProps) => {
  const { t } = useTranslation()

  const [activeBenefit, setActiveBenefit] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveBenefit(activeBenefit === BENEFITS.length - 1 ? 0 : activeBenefit + 1)
    }, 5000)
    return () => clearInterval(interval)
  }, [activeBenefit])

  return (
    <OnboardingSliderRoot
      direction="column"
      alignItems="center"
      justifyContent="space-between"
      gap={{ '@initial': '2', '@bp2': '7' }}
    >
      <div />
      <Stack direction="column" gap="7" alignItems="center">
        <ImageWrapper>
          {BENEFITS.map((benefit) => {
            return (
              <object
                key={benefit.id}
                style={{ display: benefit.id === BENEFITS[activeBenefit].id ? 'block' : 'none' }}
                type="image/svg+xml"
                data={benefit.image}
                aria-label={`${benefit.text}`}
              />
            )
          })}
        </ImageWrapper>

        <Text
          size={{ '@initial': 'h4', '@bp2': 'h2' }}
          as="h2"
          color="accent-black"
          weight="bold"
          align="center"
          id={BENEFITS[activeBenefit].id}
          aria-hidden
        >
          {t(`${BENEFITS[activeBenefit].text}`)}
        </Text>
      </Stack>

      <Stack>
        {BENEFITS.map((_, index) => (
          <SliderButton
            key={index}
            active={index === activeBenefit}
            onClick={() => setActiveBenefit(index)}
            aria-label={t('login:benefits', { index: index + 1 })}
          />
        ))}
      </Stack>
    </OnboardingSliderRoot>
  )
}

OnboardingSlider.displayName = 'OnboardingSlider'
