import { Stack } from 'components/Stack'
import { styled } from 'stitches.config'

export const OnboardingSliderRoot = styled(Stack, {
  zIndex: '$1',
  height: '100%',
})

export const ImageWrapper = styled('div', {
  flex: 1,
  size: '140px',

  '@bp2': {
    size: '210px',
  },
})

export const SliderButton = styled('button', {
  cursor: 'pointer',
  size: '$2',
  backgroundColor: 'transparent',
  borderRadius: '$pill',
  border: '3px solid $primary-700',
  variants: {
    active: {
      true: {
        backgroundColor: '$primary-700',
      },
    },
  },
})
