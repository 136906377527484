import { Box } from 'components/Box'
import { Text } from 'components/Text/Text'
import { ColorTokens, styled, theme } from 'stitches.config'

const color = Object.keys(theme.colors).reduce((acc, color) => {
  return { ...acc, [color]: { '&': { backgroundColor: `$${color}` } } }
}, {}) as { [key in ColorTokens]: any }

export const Label = styled('label', {
  position: 'absolute',
  left: '$2',
  transition: 'all 150ms ease-out',
  fontSize: '$small',
  lineHeight: '$small',
  top: '50%',
  transform: 'translateY(-50%)',
  pointerEvents: 'none',
  color: '$accent-gray',
})

export const Input = styled('input', {
  height: '100%',
  width: '100%',
  appearance: 'none',
  borderRadius: '$1',
  border: 'none',
  backgroundColor: 'transparent',
  padding: '$1 $2',
  paddingTop: '$2',
  fontSize: '$body',
  fontFamily: '$primary',

  '&:focus': {
    outline: '1px solid $accent-black',
  },

  '&:disabled': {
    cursor: 'not-allowed',
    borderColor: '$accent-black',
  },

  '&:read-only': {
    pointerEvents: 'none',
    borderColor: '$accent-black',
    backgroundColor: '$accent-white',
  },

  '&:focus, &:not(:placeholder-shown)': {
    [`& + ${Label}`]: {
      fontSize: '$caption',
      lineHeight: '$caption',
      top: '0',
      transform: 'translateY(-.7rem)',
      backgroundColor: '$accent-white',
      padding: '0px 4px',
      color: '$accent-black',
    },
  },

  // Workaround to avoid the blueish color that
  // chrome adds when the field is autofilled
  '&:-webkit-autofill': {
    transition: 'background-color 5000s ease-in-out 0s',
  },

  // Hide default arrows completely. up and down arrows still functional
  '&[type="number"]::-webkit-inner-spin-button, &[type="number"]::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },

  '&[type="number"]': {
    '-moz-appearance': 'textfield',
  },

  variants: {
    displayLabel: {
      true: {
        '&::placeholder': {
          color: 'transparent',
        },
      },
      false: {
        paddingTop: '$1',
        '&::placeholder': {
          color: '$accent-gray',
        },
      },
    },
  },
})

export const HelperText = styled(Text, {
  color: '$surface-300',
  paddingLeft: '$1',

  variants: {
    error: {
      true: {
        color: 'rgb($tag-red)',
      },
    },
    success: {
      true: {
        color: 'rgb($tag-green)',
      },
    },
    disabled: {
      true: {
        color: '$accent-gray',
      },
    },
  },
})

export const TextInputRoot = styled('div', {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  minWidth: '100%',
  borderRadius: '$1',
  border: '1px solid $accent-gray',
  color: '$accent-black',

  '@bp1': {
    minWidth: '280px',
    width: '100%',
  },

  '@bp2': {
    minWidth: '320px',
    width: '100%',
  },

  variants: {
    error: {
      true: {
        [`&, &:focus-within, & ${Input}`]: {
          color: 'rgb($tag-red)',
          borderColor: 'rgb($tag-red)',
        },
      },
    },
    disabled: {
      true: {
        [`&, &:focus-within, & ${Input}`]: {
          borderColor: '$accent-gray',
          color: '$accent-gray',
          backgroundColor: '$accent-white',
        },
      },
    },
    borderless: {
      true: {
        [`&, & ${Input}`]: {
          border: 'none',
        },
      },
    },
    sideBorderless: {
      bottom: {
        borderBottom: 'none',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
      top: {
        borderTop: 'none',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      },
    },
    hasSuffix: {
      true: {
        paddingRight: '0',
      },
    },
    short: {
      true: {
        minWidth: '130px',
        width: '100%',
      },
    },
    color,
  },

  defaultVariants: {
    color: 'accent-white',
  },
})

export const Wrapper = styled(Box, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',

  variants: {
    widthStyle: {
      full: {
        width: '100%',
      },
      half: {
        width: '100%',
        '@bp2': {
          width: '50%',
        },
      },
    },
  },

  '& > div': {
    width: '100%',
  },
})
