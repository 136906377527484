import { styled } from 'stitches.config'
import { Stack } from 'components/Stack'
import { Button } from 'components/Button'

export const OnboardingRoot = styled(Stack, {
  backgroundColor: '$tertiary-200',
  padding: '$4 $3',
  height: '100vh',

  '@bp1': {
    padding: '$10 144px',
  },

  variants: {
    isOnboardingDone: {
      true: {
        display: 'none',
        '@bp3': {
          display: 'block',
        },
      },
    },
  },
})

export const MobileButton = styled(Button, {
  '@bp3': {
    display: 'none',
  },
})
