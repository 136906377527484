import { Stack } from 'components/Stack'
import { styled } from 'stitches.config'

export const Content = styled('div', {
  flex: 1,
  minWidth: '50%',
  display: 'none',

  variants: {
    isOnboardingDone: {
      true: {
        display: 'block',
      },
    },
  },

  '@bp3': {
    display: 'block',
  },
})

export const Wrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',

  variants: {
    isOnboardingDone: {
      true: {
        background: '$tertiary-200',
        '@bp3': {
          background:
            'linear-gradient(90deg, $tertiary-200 0%,  $tertiary-200 50%,  $tertiary-200 51%,  $tertiary-200 100%)',
        },
      },
      false: {
        background: '$tertiary-200',
        '@bp3': {
          background:
            'linear-gradient(90deg, $tertiary-200 0%,  $tertiary-200 50%,  $tertiary-200 51%,  $tertiary-200 100%)',
        },
      },
    },
  },
})

export const LoginLayoutRoot = styled(Stack, {
  position: 'relative',
  minHeight: '100vh',

  '& > *:first-child': {
    flex: 1,
  },

  '@bp3': {
    maxWidth: '$max',
  },
})

export const MeatPattern = styled('div', {
  display: 'none',

  '@bp3': {
    display: 'block',
    width: '172px',
    height: '138px',
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: 0,
  },
})
