import Image from 'next/image'
import React, { useState, useLayoutEffect, ReactNode } from 'react'
import { CookieKeys, getCookie, setCookie } from 'services/Cookies'
import { Onboarding } from '../Onboarding'
import { LoginLayoutRoot, Wrapper, Content, MeatPattern } from './LoginLayout.styles'

import loginPattern from '/public/assets/pattern-login-01.png'

type LoginLayoutProps = {
  skipOnboarding?: boolean
  children?: ReactNode
}

export const LoginLayout: React.FC<LoginLayoutProps> = ({ children, skipOnboarding = false }) => {
  const [isOnboardingDone, setIsOnboardingDone] = useState(true)

  const onClick = () => {
    setIsOnboardingDone(true)
  }

  return (
    <Wrapper isOnboardingDone={isOnboardingDone}>
      <LoginLayoutRoot gap="0" justifyContent="center" alignItems="center">
        <Onboarding isOnboardingDone={isOnboardingDone} onClick={onClick} />
        <Content isOnboardingDone={isOnboardingDone}>{children}</Content>
      </LoginLayoutRoot>
      <MeatPattern>
        <Image src={loginPattern} alt="" />
      </MeatPattern>
    </Wrapper>
  )
}

LoginLayout.displayName = 'LoginLayout'
