import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { OnboardingSlider } from '../OnboardingSlider'
import { OnboardingRoot, MobileButton } from './Onboarding.styles'

type OnboardingProps = {
  isOnboardingDone: boolean
  onClick: () => void
}

export const Onboarding = ({ isOnboardingDone, onClick, ...rest }: OnboardingProps) => {
  const { t } = useTranslation()

  return (
    <OnboardingRoot direction="column" {...rest} gap="3" isOnboardingDone={isOnboardingDone}>
      <OnboardingSlider />
      <MobileButton onClick={onClick}>{t('login:onboarding.cta')}</MobileButton>
    </OnboardingRoot>
  )
}

Onboarding.displayName = 'Onboarding'
