import React, { ComponentProps, forwardRef } from 'react'
import { VariantProps } from '@stitches/react'
import { Wrapper, HelperText, Input, Label, TextInputRoot } from './TextInput.styles'
import { useId } from '@radix-ui/react-id'
import { Stack } from 'components/Stack'
import { SpaceTokens } from 'stitches.config'
import { removeDiacriticsHelper } from '../../utils/removeDiacriticsHelper'

type TextInputVariants = VariantProps<typeof TextInputRoot>
type TextInputOwnProps = ComponentProps<typeof Input>
export type TextInputProps = TextInputOwnProps &
  TextInputVariants & {
    outerLabel?: (props: { children: string; htmlFor: string }) => JSX.Element
    outerLabelGap?: SpaceTokens
    helperText?: string
    label: string
    displayLabel?: boolean
    displayHelperText?: boolean
    suffix?: React.ReactNode
    removeDiacritics?: boolean
    widthStyle?: 'full' | 'half'
  }

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      id,
      helperText,
      label,
      value,
      error,
      disabled,
      readOnly,
      borderless,
      color,
      displayLabel = true,
      displayHelperText = true,
      suffix,
      short,
      outerLabel,
      outerLabelGap = 2,
      placeholder = '\u00A0',
      sideBorderless,
      removeDiacritics,
      widthStyle,
      ...rest
    },
    forwardedRef
  ) => {
    const textInputId = useId(id)
    const inputProps = {
      disabled,
      readOnly,
      value,
      placeholder,
      ...rest,
    }

    const statusProps = {
      error,
      disabled: disabled || readOnly,
    }

    const variantProps = {
      ...statusProps,
      borderless,
      color,
      hasSuffix: !!suffix,
      short,
      sideBorderless,
    }

    const handleKeyup = (e: any) => {
      if (!e.target.value) return
      const refreshedString = removeDiacriticsHelper(e.target.value)
      e.target.value = refreshedString
    }

    return (
      <Wrapper widthStyle={widthStyle}>
        <Stack direction="column" gap={outerLabelGap}>
          {outerLabel?.({ htmlFor: textInputId, children: label })}
          <TextInputRoot {...variantProps}>
            <Input
              id={textInputId}
              aria-label={label}
              aria-labelledby={ displayLabel ? textInputId + '-label' : undefined }
              displayLabel={displayLabel}
              {...inputProps}
              ref={forwardedRef}
              onKeyUp={removeDiacritics ? handleKeyup : undefined}
            />
            {displayLabel && (
              <Label id={textInputId + '-label'} htmlFor={textInputId}>
                {label}
              </Label>
            )}
            <div style={{ position: 'absolute', right: '0', marginRight: '10px' }}>{suffix}</div>
          </TextInputRoot>
        </Stack>
        {displayHelperText && (
          <HelperText size="caption" {...statusProps}>
            {helperText || ''}
          </HelperText>
        )}
      </Wrapper>
    )
  }
)

TextInput.displayName = 'TextInput'
